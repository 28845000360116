<template>
  <div class="col-12 md-col-5 login-box elevation-5">
    <!-- <p class="formTitle">
      {{ $t('Login.title') }}
    </p> -->

    <x-formgroup
      ref="form"
      class="form-box"
      @submit.prevent="() => $refs.submitButton.click()"
    >
      <v-layout
        row
        wrap
      >
        <v-flex md6>
          <x-select-filter
            v-model="formScheme.company.value"
            class="layout flex column"
            v-bind="formScheme.company"
          />
        </v-flex>
        <v-flex md6>
          <x-select-filter
            v-model="formScheme.language.value"
            class="layout flex column"
            v-bind="formScheme.language"
          />
        </v-flex>
      </v-layout>
      <x-textfield
        v-model="formScheme.email.value"
        class="layout flex column xs12"
        v-bind="formScheme.email"
      />
      <x-textfield
        v-model="formScheme.password.value"
        v-bind="formScheme.password"
        class="layout flex column xs12"
        @keyup.enter="submit"
      />
    </x-formgroup>

    <div class="text-xs-center btnGroup">
      <x-button
        ref="submitButton"
        v-bind="formScheme.submitButton"
        :disabled="$store.loading"
        class="btnPrimary"
      >
        {{ $t('Login.btn_submit') }}
      </x-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { fetchCompanyList } from '../../../apis/company.js'
import { capitalize } from '../../../utils/string.js'

export default {
  data () {
    return {
      companyList: [],
      cardScheme: {
        title: this.$t('Login.title'),
        titleClass: 'login-title caps elevation-2'
      },
      formScheme: {
        company: {
          id: 'company',
          name: 'company',
          label: this.$t('Login.company'),
          placeholder: this.$t('Login.company_placeholder'),
          value: 2,
          required: true,
          rules: [v => !!v || this.$t('validation.required')],
          items: []
        },
        language: {
          id: 'language',
          name: 'language',
          label: this.$t('Login.language'),
          value: 'en',
          required: true,
          rules: [v => !!v || this.$t('validation.required')],
          items: [{ text: 'English', value: 'en' }]
        },
        email: {
          id: 'email',
          name: 'email',
          type: 'email',
          label: this.$t('Login.email'),
          required: true,
          rules: [v => !!v || this.$t('Login.email_invalid')],
          appendIcon: 'email'
        },
        password: {
          id: 'password',
          name: 'password',
          type: 'password',
          label: this.$t('Login.password'),
          required: true,
          rules: [v => !!v || this.$t('Login.password_invalid')],
          appendIcon: 'lock'
        },
        submitButton: {
          size: 'large',
          block: true,
          shadow: true,
          click: this.submit
        }
      }
    }
  },
  async mounted () {
    this.$store.loading = true
    try {
      const { data } = await fetchCompanyList()
      this.companyList = data
      this.formScheme.company.items = data.map(c => ({
        value: c.id,
        text: capitalize(c.name)
      }))
    } catch (e) {
      this.$store.errorNotification = e
    }
    this.$store.loading = false
  },
  methods: {
    validate () {
      if (!this.$refs.form.$children[0].validate()) {
        throw this.$t('validation.general')
      }
    },
    async submit () {
      this.$store.loading = true
      const company = this.formScheme.company.value
      const email = this.formScheme.email.value
      const language = this.formScheme.language.value
      const password = this.formScheme.password.value
      try {
        this.validate()
        const { data: user } = await this.$User.login({
          email,
          password,
          companyId: company,
          language,
        })
        Vue.config.isLogin = true
        this.setCookies({ company, language })

        const { redirect } = this.$route.query
        this.$store.user = user
        if (redirect) return this.$router.replace(redirect)

        return this.$router.replace('/')
      } catch (err) {
        this.$store.errorNotification = err
      }
      this.$store.loading = false
    },
    setCookies ({ company, language }) {
      const { timezone } = this.companyList.find(c => c.id === company)
      window.document.cookie = `oTimezone=${timezone}`
      window.document.cookie = `oCompany=${company}`
      window.document.cookie = `oLauguage=${language}`
    }
  }
}
</script>

<style lang="stylus" scoped>
.login-box {
  border-radius: 10px;
  background: $white;
  padding: 40px;

  .login-title {
    background-color: $gary;
    letter-spacing: 0.2rem;
  }

  .formTitle {
    background: -webkit-linear-gradient(#2C204B,#7D719B);
    background: -o-linear-gradient(#2C204B,#7D719B);
    background: -moz-linear-gradient(#2C204B,#7D719B);
    background: linear-gradient(#2C204B,#7D719B);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 25px 6px;
    margin: 0;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .btnGroup {
    button {
      box-shadow: none;
      margin: 0;
    }
  }
}

@media screen and (min-width: 646px) {
  form {
    .md6 {
      &:nth-child(1) {
        padding: 0 5px 0 0;
      }
      &:nth-child(2) {
        padding: 0 0 0 5px;
      }
    }
  }
}
</style>
